<template>
  <index-content>
    <template #options>
      <nb-download :url="CSV_URL" />
    </template>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th rowspan="2" class="id">{{ $t('label.id') }}</th>
            <th rowspan="2" class="name">{{ $t('label.name') }}</th>
            <th rowspan="2" class="abbreviation">{{ $t('label.abbreviation') }}</th>
            <th rowspan="2" class="encoding">{{ $t('label.encoding') }}</th>
            <th colspan="2" class="currency">{{ $t('label.currency') }}</th>
            <th rowspan="2" class="status">{{ $t('label.status') }}</th>
            <th rowspan="2" class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th v-table-action rowspan="2">{{ $t('label.action') }}</th>
          </tr>
          <tr>
            <th class="assess">{{ $t('label.assess') }}</th>
            <th class="bridge">{{ $t('label.bridge') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.abbreviation }}</td>
            <td>{{ row.encoding }}</td>
            <td v-selection:[`market.currency`]="row.assessCurrency"></td>
            <td v-selection:[`market.currency`]="row.bridgeCurrency"></td>
            <td v-enums:CompanyStatus="row.status"></td>
            <td>{{ row.updateDatetime | datetime }}</td>
            <td class="n-button-box">
              <nb-custom text="allocations" custom-icon="fa fa-bars" @on-click="toAllocations(row)"></nb-custom>
              <n-dropdown
                  type="info"
                  text="more"
                  :items="items"
                  @on-click="
                  k => {
                    if (k === 'config') {
                      toConfig(row);
                    } else if (k === 'holiday') {
                      toHoliday(row);
                    } else if (k === 'currency') {
                      toCurrencies(row);
                    }
                  }
                "
              ></n-dropdown>
              <nb-modal @on-click="openModal(row)"></nb-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import { getSelection } from '@/store';
import ModalCompanyCompany from './ModalCompanyCompany';
import { CSV_URL, fetch, conversions } from '@/api/company/company-company';

export default BaseCompanyView.extend({
  name: 'CompanyCompany',

  data() {
    return {
      CSV_URL,
      preload: true,
      items: [
        { key: 'config', text: 'configs' },
        { key: 'holiday', text: 'holidays' },
        { key: 'currency', text: 'currencies' },
      ],
    };
  },

  methods: {
    doLoad() {
      return fetch();
    },

    parse(data) {
      this.records = data;
    },

    openModal(model) {
      this.createModal(ModalCompanyCompany, { model, on: this });
    },

    toConfig(o) {
      this.pushMenu({ url: `/company/company/${o.id}/configs` });
    },

    toHoliday(o) {
      this.pushMenu({ url: `/company/company/${o.id}/holidays` });
    },

    toCurrencies(o) {
      this.pushMenu({ url: `/company/company/${o.id}/currencies` });
    },

    toAllocations(o) {
      this.pushMenu({ url: `/company/company/${o.id}/allocations` });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  th.id {
    width: 4rem;
  }

  th.name {
    width: 15rem;
  }

  th.rw-action {
    width: 14.5rem;
  }

  th.ro-action {
    width: 15rem;
  }
}
</style>
