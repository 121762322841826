<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" @submit.native.prevent>
      <!-- prettier-ignore -->
      <Tabs type="card" name="modalCompany" :animated="false">
        <TabPane tab="modalCompany" name="basic" :label="this.$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <!-- name -->
            <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
            <!-- abbreviation -->
            <n-field-text v-model="model.abbreviation" field-name="abbreviation" label="abbreviation" :error="error.abbreviation" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- status -->
            <n-field-enum v-model="model.status" field-name="status" enum-name="CompanyStatus" :error="error.status" label="status" :disabled="disabled" />
            <!-- taxRate -->
            <n-field-percent v-model="model.taxRate" format="0 %" field-name="taxRate" :error="error.taxRate" label="taxRate" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- version -->
            <n-field-id v-model="model.version" label="version" disabled />
            <!-- encoding -->
            <n-field-text v-model="model.encoding" field-name="encoding" label="encoding" :error="error.encoding" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- assessCurrency -->
            <n-field-selection v-model="model.assessCurrency" field-name="assessCurrency" selection-name="market.currency" :error="error.assessCurrency" label="assessCurrency" :disabled="isEdit || disabled" />
            <!-- bridgeCurrency -->
            <n-field-selection v-model="model.bridgeCurrency" field-name="bridgeCurrency" selection-name="market.currency" :error="error.bridgeCurrency" label="bridgeCurrency" :disabled="isEdit || disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalCompany" name="contact" :label="this.$t('label.contact')" class="n-modal-container">
          <n-row-col2>
            <!-- email -->
            <n-field-text v-model="model.email" field-name="email" :error="error.email" label="email" :disabled="disabled" />
            <!-- phone -->
            <n-field-text v-model="model.phone" field-name="phone" :error="error.phone" label="phone" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- zipCode -->
            <n-field-text v-model="model.zipCode" field-name="zipCode" :error="error.zipCode" label="zipCode" :disabled="disabled" />
            <!-- licence -->
            <n-field-text v-model="model.licence" field-name="licence" :error="error.licence" label="licence" :disabled="disabled" />
          </n-row-col2>
          <n-row-col1>
            <!-- address1 -->
            <n-field-text v-model="model.address1" field-name="address1" :error="error.address1" label="address1" :disabled="disabled" />
          </n-row-col1>
          <n-row-col1>
            <!-- address2 -->
            <n-field-text v-model="model.address2" field-name="address2" :error="error.address2" label="address2" :disabled="disabled" />
          </n-row-col1>
        </TabPane>
        <TabPane tab="modalCompany" name="activity" :label="this.$t('label.activity')" class="n-modal-container">
          <n-row-col2>
            <!-- individual -->
            <n-field-select v-model="model.individual" field-name="individual" :options="groupOptions" :error="error.individual" label="individual" :disabled="disabled" />
            <!-- corporation -->
            <n-field-select v-model="model.corporation" field-name="corporation" :options="groupOptions" :error="error.corporation" label="corporation" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- loginRestriction -->
            <n-field-enum v-model="model.loginRestriction" field-name="loginRestriction" enum-name="LoginRestriction" :error="error.loginRestriction" label="loginRestriction" :disabled="disabled" />
            <!-- tradingRestriction -->
            <n-field-enum v-model="model.tradingRestriction" field-name="tradingRestriction" enum-name="TradingRestriction" :error="error.tradingRestriction" label="tradingRestriction" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- editingRestriction -->
            <n-field-enum v-model="model.editingRestriction" field-name="editingRestriction" enum-name="EditingRestriction" :error="error.editingRestriction" label="editingRestriction" :disabled="disabled" />
            <!-- transferRestriction -->
            <n-field-enum v-model="model.transferRestriction" field-name="transferRestriction" enum-name="TransferRestriction" :error="error.transferRestriction" label="transferRestriction" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { getSelectionByCompanyId } from '@/store';
import { validate, edit } from '@/api/company/company-company';

export default NModal.extend({
  name: 'ModalCompanyCompany',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return edit(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },

  data() {
    return {
      taxRateScale: 2,
    };
  },

  computed: {
    groupOptions: function() {
      return getSelectionByCompanyId('company.group', this.model.id);
    },
  },
});
</script>
