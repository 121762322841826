import { $fetch, $post } from '@/services/axios';

export const CSV_URL = `company/companies/csv`;

export function fetch() {
  return $fetch(`company/companies`);
}

export function validate(data) {
  return $post(`company/validate`, data);
}

export function edit(data) {
  return $post(`company/${data.id}`, data);
}

export function conversions(data) {
  return $fetch(`company/${data.id}/conversions`);
}
